@tailwind base;
@tailwind components;
@tailwind utilities;

.authBackground {
  width: 100%;
  height: 100%;
  background-image: url('../public/Shape-1.svg'), url('../public/Oval.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: bottom right, top left;
  background-size: 30%, 25%;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tree-menu ul {
  padding-left: 10px;
}

.tree-menu ul li {
  list-style-type: none;
  padding-left: 30px;
  position: relative;
}

.tree-menu ul li:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0 0 0 0px;
  height: 100%;
}

.tree-menu ul li:after {
  content: "";
  display: block;
  position: absolute;
  top: 2em;
  left: 0px;
  border-top: 1px solid #ccc;
  border-radius: 0px 0 0 0;
  width: 30px;
  height: 100%;
}

.tree-menu ul li:last-of-type:before {
  height: 2em;
}

.area {
  fill: url(#area-gradient);
}

.axis path,
.axis line {
  fill: none;
  stroke-width: 0;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #F8F8F8;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid #ccc;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}